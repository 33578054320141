import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ breakpoints, typography }) => ({
    banner: { lineHeight: 1.1 },
    section: { lineHeight: 1.2 },

    [breakpoints.up('sm')]: {
      banner: { fontSize: typography.h1.fontSize },
      section: { fontSize: typography.h3.fontSize },
    },
  }),
  { name: 'LandingPageHeaderTypography' },
);

export default function LandingPageHeaderTypography(props) {
  const { className, type = 'section', ...rest } = props;
  const classes = useStyles(props);

  const typeProps =
    type === 'section' ?
      {
        className: classNames(classes.section, className),
        component: 'h2',
        variant: 'h4',
      }
    : {
        className: classNames(classes.banner, className),
        component: 'h1',
        variant: 'h3',
      };
  return <Typography {...typeProps} {...rest} />;
}

LandingPageHeaderTypography.propTypes = {
  type: PropTypes.oneOf(['banner', 'section']),
};
